<script setup>
import Icon from '@/Components/Icon/index.vue';
import { computed } from 'vue';
  const props = defineProps({
    imageSet: {
      type: Object,
      required: false,
    },
    altText: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "md",
      validator: (v) =>
        [
          "md",
          "lg",
          "fit"
        ].indexOf(v) !== -1,
    },
    fallbackIcon: {
      type: String,
      required: false,
    }
  });

  const style = {
    md: "h-16 w-16",
    lg: "h-24 w-24",
    full: "w-fit",
  };

const imageUrl =  computed(() => {
  if(!props.imageSet && !props.imageSet?.original) {
    return null;
  }

  return props.imageSet.original;
});

</script>
<template>
  <div class="mb-3">
    <component :is="link ? 'a' : 'div'" v-if="imageUrl" tabindex="-1" :href="link">
      <img
        :src="imageUrl"
        :alt="altText"
        class="object-contain border border-primary-700 rounded-md border-solid"
        :class="[style[size]]"
      >
    </component>
    <div
      v-else
      :class="style[size]"
      class="flex items-center justify-center"
    >
    <Icon v-if="fallbackIcon" :path="fallbackIcon" :height="40" :width="40"></Icon>
  </div>  
  </div>
</template>