<script setup>
import { ref } from 'vue';
const props = defineProps({
  size: {
    type: String,
    required: true,
  },
  styleSize: {
    type: String,
    required: true,
  }
});

const headingStyle = ref({
  h1: "font-au-sans-regular text-4xl",
  h2: "font-au-sans-regular text-3xl",
  h3: "font-au-sans-regular text-xl",
});
</script>

<template>
  <component
    :is="size"
    :class="headingStyle[styleSize]"
  >
    <slot />
  </component>
</template>