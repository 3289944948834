<script setup>
import Button from '@/Components/Button.vue';
import Icon from '@/Components/Icon/index.vue';
import { useScroll } from '@vueuse/core';

const scrollTop = () => {
  window.scrollTo(0, 0);
}

const { y } = useScroll(window);
</script>

<template>
  <Transition
    enter-active-class="duration-150 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-150 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <button v-if="y > 100" class=" fixed bottom-8 right-8 bg-white rounded-full border-grey border-2 flex justify-center items-center" @click="scrollTop">
      <Icon path="ArrowUpward" :height="48" :width="48"></Icon>
    </button>
  </Transition>
</template>