
<script setup>
import { computed, defineAsyncComponent } from 'vue';

  const props = defineProps({
    path: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    vAlign: {
      type: String,
      required: false,
      default: null,
    },
  });

  const icon = defineAsyncComponent(() => import(`../../Components/Icon/${props.path}.vue`));

  const emit = defineEmits('click');
    const click = () => {
      emit('click');
    };

    const alignClass = computed(() => {
      return props.vAlign ? `align-${props.vAlign}` : null;
    });

</script>

<template>
  <component
    :is="icon"
    :class="[alignClass]"
    :width="width"
    :height="height"
    :alt="ariaLabel ? ariaLabel : ''"
    :color="color"
    :role="ariaLabel ? 'img' : 'presentation'"
    @click="click"
  />
</template>