
<script setup>
    const setFocusToMainContent = () => {
      document.getElementById('main-content').focus();
    };
    const setFocusToFooter = () => {
      document.getElementById('footer-content').focus();
    };
</script>
<template>
  <button
    role="link"
    class="absolute left-[-999px] z-[9999] p-3 bg-primary text-white border-0 opacity-0 focus:opacity-100 focus:top-8 focus:left-10 translate-[-50%] transition-none"
    @click="setFocusToMainContent"
  >
    Skip to main content
  </button>
  <button
    role="link"
    class="absolute left-[-999px] z-[9999] p-3 bg-primary text-white border-0 opacity-0 focus:opacity-100 focus:top-8 focus:left-10 translate-[-50%] transition-none"
    @click="setFocusToFooter"
  >
    Skip to footer
  </button>
</template>
