<script setup>
import { ref } from 'vue';
import SkipToMainButton from '../SkipToMainButton.vue';
import Icon from '@/Components/Icon/index.vue';
import Button from '@/Components/Button.vue';

const menuItems = ref([
	{
		title: "Why Australia",
		description: "Australia is ideal for international students, with high-quality education, a welcoming culture, vibrant student cities and beautiful natural surroundings.",
		link: "https://www.studyaustralia.gov.au/en/why-australia",
		items: [
			{
				label: "World class education",
				link: "https://www.studyaustralia.gov.au/en/why-australia/world-class-education"
			},
			{
				label: "Australian culture and lifestyle",
				link: "https://www.studyaustralia.gov.au/en/why-australia/australian-culture-and-lifestyle"
			},
			{
				label: "Real world learning",
				link: "https://www.studyaustralia.gov.au/en/why-australia/real-world-learning"
			},
			{
				label: "Student chat",
				link: "https://www.studyaustralia.gov.au/en/why-australia/student-chat"
			},
		]
	},
	{
		title: "Plan Your Studies",
		description: "Australia has a range of learning institutions, including universities, vocational education and training institutes, schools and English language colleges.",
		link: "https://www.studyaustralia.gov.au/en/plan-your-studies",
		items: [
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/find-a-course", label: "Find a course"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/how-to-apply-to-study", label: "How to apply to study"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/australias-education-system", label: "Australia’s education system "},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/areas-of-study", label: "Areas of study"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/english-courses", label: "English courses"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/schools", label: "Schools"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/vocational-education-and-training", label: "Vocational education and training"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/universities-and-higher-education", label: "Universities and higher education"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/scholarships", label: "Scholarships"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/study-abroad-and-exchange", label: "Study abroad and exchange"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/other-ways-to-study", label: "Other ways to study"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/list-of-australian-universities", label: "List of Australian universities"},
			{link: "https://www.studyaustralia.gov.au/en/plan-your-studies/changing-your-course-or-provider", label: "Changing your course or provider"},
		]
	},
	{
		title: "Life in Australia",
		link: "https://www.studyaustralia.gov.au/en/life-in-australia",
		description: "Life in Australia offers international students a welcoming and diverse culture, a high standard of living, access to student support services and more.",
		items: [
			{link: "https://www.studyaustralia.gov.au/en/life-in-australia/locations-in-australia", label: "Locations in Australia"},
			{link: "https://www.studyaustralia.gov.au/en/life-in-australia/accommodation", label: "Accommodation"},
			{link: "https://www.studyaustralia.gov.au/en/life-in-australia/living-and-education-costs", label: "Living and education costs"},
			{link: "https://www.studyaustralia.gov.au/en/life-in-australia/safety-in-australia", label: "Safety in Australia"},
			{link: "https://www.studyaustralia.gov.au/en/life-in-australia/student-support-services", label: "Student support services"},
		]
	},
	{
		title: "Plan your move",
		link: "https://www.studyaustralia.gov.au/en/plan-your-move",
		description: "Find the information you’ll need as an international student to move to Australia and set yourself up for success.",
		items: [
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/what-can-i-bring-to-australia", label: "What can you bring to Australia? "},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/your-first-week-in-australia", label: "Your first week in Australia"},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/preparing-to-travel", label: "Preparing to travel"},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/your-guide-to-visas", label: "Your guide to visas"},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/bringing-your-family", label: "Bringing your family"},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/overseas-student-health-cover-oshc", label: "Overseas Student Health Cover (OSHC) "},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/visa-application-process", label: "How to apply for your visa"},
		{link: "https://www.studyaustralia.gov.au/en/plan-your-move/language-testing-organisations", label: "Language testing organisations"},
		]
	},
	{
		title: "Work in Australia",
		link: "/en/work-in-australia",
		description: "International students in Australia can work while they study, with access to a wide range of options for building networks and gaining valuable industry experience.",
		items: [
			{link: "/en/work-in-australia/preparing-for-work", label: "Preparing for work"},
			{link: "/en/work-in-australia/finding-work-while-you-study", label: "Finding work while you study"},
			{link: "/en/work-in-australia/work-rights-and-responsibilities", label: "Work rights and responsibilities "},
			{link: "/en/work-in-australia/getting-work-and-industry-experience", label: "Volunteer and industry experience"},
			{link: "/en/work-in-australia/work-after-graduating", label: "Work after graduating"},
			{link: "/en/work-in-australia/student-employability-stories", label: "Student Employability Stories"},
		],
	},
	{
		title: "Tools and resources",
		link: "/en/tools-and-resources",
		description: "Masterclasses, inspiring student stories and helpful tips and advice (blogs).",
		items: [
			{link: "/en/tools-and-resources/news", label: "News"},
		{link: "/en/tools-and-resources/masterclass-library", label: "Masterclass library"},
		{link: "/en/tools-and-resources/student-stories", label: "Student stories"},
		{link: "/en/tools-and-resources/tips-and-advice-for-students", label: "Tips and advice for students"},
		]
	}
]);

const showMobileMenu = ref(false);
const toggleMobileMenu = () => {
	showMobileMenu.value = !showMobileMenu.value
	if(!showMobileMenu.value) {
		activeMobileMenu.value = null;
	}
}

const activeMobileMenu = ref(null);
const setActiveMobileMenu = (menuTitle) => {
	if(!menuTitle) {
		activeMobileMenu.value = null;
		return
	}
	const activeMenuIndex = menuItems.value.findIndex((e) => e.title == menuTitle);

	if(activeMenuIndex != -1) {
		activeMobileMenu.value = menuItems.value[activeMenuIndex]
	}
}
</script>

<template>
  <SkipToMainButton />
  <header >
    <div class="container py-4">
      <a
        href="https://studyaustralia.gov.au"
        class="border-none md:flex align-items-center"
      >
        <img
class="max-w-[210px] py-1 me-8 mb-4 md:mb-0"
          src="/assets/images/austgovt-logo.svg"
          alt="Australian Government"
        ><img
class="max-w-[220px] py-1 me-3"
          src="/assets/images/studyau-logo.svg"
          alt="Study Australia"
        >
      </a>
    </div>
  </header>
  <!-- <header> -->

  <nav
    class="hidden lg:block"
  >
	<div class="bg-primary-200 py-1">
		<div class="container">
      <div class="flex justify-between relative">

        <div
          v-for="item in menuItems"
          :key="`title-item-${item.title}`"
          class="static pe-3 group"
        >
          <a
            :href="item.link"
          >{{ item.title }}<Icon path="ChevronDown" :height="16" :width="16" class="inline-block"/></a>	
          <div class="inset-x-0 w-full p-8 z-[9999] bg-white container absolute animate-slide-in animate hidden group-hover:block">
            <div class="grid grid-cols-12 gap-3">
              <div class="col-span-3">
                <p class="font-display-regular text-xl text-primary mb-3">
                  <a :href="item.link"><Icon path="ChevronDown" :height="16" :width="16" class="inline-block"/> {{ item.title }}</a>
                </p>
                <hr class="mb-3 w-100">
                <p class="text-xs">
                  {{ item.description }}
                </p>
              </div>
              <div class="col-span-9">
                <ul class="block columns-3">
                  <li
                    v-for="link in item.items"
										:key="`list-item-${link.label}`"
										class="transition-colors duration-100 hover:bg-primary-100 hover:text-primary-600 p-1"
                  >
                    <a
                      :href="link.link"
                    >{{ link.label }}</a>
                  </li>  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
  
  </nav>
  <section class="lg:hidden min-h-8 h-8 bg-primary-200">
		<div class="container ">
			<div class="flex items-center justify-end w-full">
					<Button variant="no-style" @click="toggleMobileMenu" >
						<Icon path="Menu" class="text-primary" :height="32" :width="32"/>
					</button>
			</div>
		</div>
	</section>

		<Transition
			enter-active-class="duration-150 ease-out"
			enter-from-class="transform translate-x-full"
			enter-to-class="translate-x-0"
			leave-active-class="duration-150 ease-in"
			leave-from-class="translate-x-0"
			leave-to-class="transform translate-x-full"
		>
		<nav v-if="showMobileMenu" class="fixed z-[9999] inset-0">
			<div class="ms-auto w-[600px] max-w-[95%] bg-white h-full">
				<div class="p-4 h-2 flex w-full justify-end">
					<Button v-if="activeMobileMenu != null" class="me-auto" variant="no-style" @click="setActiveMobileMenu(false)" >
						<Icon path="ArrowBack" :height="32" :width="32"/>
					</button>
					<Button variant="no-style" @click.stop="toggleMobileMenu"><Icon path="Close" :height="32" :width="32"/></Button>
				</div>
				<div class="p-6">
						<ul v-if="!activeMobileMenu">
							<li 
							 v-for="item in menuItems"
							 :key="`mobile-title-item-${item.title}`"
          class="transition-colors duration-100 hover:bg-primary-100 p-1 cursor-pointer"
							@click.stop="setActiveMobileMenu(item.title)">
								<Button variant="no-style" class="text-2xl text-primary-600 font-display-regular text-left">{{item.title}} <Icon class="inline-block" path="ChevronRight" :height="32" :width="32"/></Button>
							</li>
						</ul>
						<div v-if="activeMobileMenu">
							
              <Button variant="no-style" class="text-2xl text-primary-600 font-display-regular"><Icon class="inline-block" path="ChevronRight" :height="32" :width="32"/>{{ activeMobileMenu.title }}</Button>
              <p class="mb-4 text-xs">{{ activeMobileMenu.description }} </p>
              <hr class="mb-4 w-full">
                <ul>
                  <li
                    v-for="link in activeMobileMenu.items"
										:key="`list-item-${link.label}`"
										class="transition-colors duration-100 hover:bg-primary-100 hover:text-primary-600 p-1"
                  >
                    <a
											class="w-full block"
                      :href="link.link"
                    >{{ link.label }}</a>
                  </li>  
                </ul>
            </div>
				</div>
			</div>
		</nav>
	</Transition>
<!-- </header> -->
</template>
