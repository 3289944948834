<script setup>
import { Link } from "@inertiajs/vue3";
import { ref, computed } from "vue";
const props = defineProps({
  variant: {
    type: String,
    default: "primary",
    validator: (v) =>
        [
          "primary",
          "secondary",
          "outlined",
          "no-style",
          "pagination",
          "link"
        ].indexOf(v) !== -1,
  },
  type: {
    type: String,
    default: "button",
    validator: (v) =>
        [
          "button",
          "link",
        ].indexOf(v) !== -1,
  },
  link: {
    type: String,
    required: false,
  },
  external: {
    type: Boolean,
    default: false,
  },
  linkData: {
    type: String,
    required: false,
  },
  ariaLabel: {
    type: String,
    required: false,
  }
});
const buttonType = computed(() => {
  return props.external ? "a" : "link" == props.type ? Link : "button";
})

const variantThemes = ref({
  "primary": "bg-primary text-white text-center font-au-sans-medium border-2 border-solid border-primary rounded-3xl py-2 px-4 hover:bg-white hover:text-primary",
  "secondary": "bg-primary-500 text-center text-primary font-au-sans-medium border-2 border-solid border-primary-500 hover:bg-white rounded-3xl py-2 px-4",
  "outlined": "bg-transparent text-center border-2 border-primary font-au-sans-medium border-solid text-primary hover:bg-primary-500 rounded-3xl py-2 px-4",
  "no-style": "h-fit w-fit text-left bg-transparent text-primary border-2 border-transparent border-solid",
  "pagination": "text-center my-auto w-8 h-8 text-primary flex items-center justify-center rounded-full",
  "link": "bg-transparent text-center text-primary font-au-sans-medium border-0 rounded-3xl py-2 px-4 hover:underline hover:text-austrade"
});

</script>

<template>
  <component
    :is="buttonType"
    :role="type"
    :aria-label="type == 'link' ? null : ariaLabel"
    :data="linkData"
    :target="external ? '_blank' : '_self'"
    :href="link"
    class="inline-block transition duration-150"
    :class="[variantThemes[variant], type == 'link' ? 'no-underline' : '']"
  >
    <slot />
  </component>
</template>
