<script setup>
import Button from '@/Components/Button.vue';
import Icon from '@/Components/Icon/index.vue';
const quickLinks = [
  {
    link: 'https://www.studyaustralia.gov.au/en/why-australia',
    title: 'Why Australia'
  },
  {
    link: 'https://www.studyaustralia.gov.au/en/plan-your-move/visa-application-process',
    title: 'Visa and travel advice'
  },
  {
    link: 'https://www.studyaustralia.gov.au/en/life-in-australia/living-and-education-costs',
    title: 'Living costs'
  },
  {
    link: 'https://www.studyaustralia.gov.au/en/life-in-australia/locations-in-australia',
    title: 'Frequently asked questions'
  },
]

const usefulTools = [
  {
    link: 'https://www.careermatcher.au/?cl=SAUS:2',
    title: 'Career matcher'
  },
  {
    link: 'https://speaking-english-practice-test.studyaustralia.gov.au/',
    title: 'Speaking English practice test'
  },
  {
    link: 'https://costofliving.studyaustralia.gov.au/',
    title: 'Cost of living calculator'
  },
]

const socials = [
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/company/australian-trade-commission'
  },
  {
    name: 'Twitter',
    link: 'http://twitter.com/Austrade'
  },
  {
    name: 'Youtube',
    link: 'http://www.youtube.com/user/austrade'
  }
]
</script>

<template>
  <div
    class="mt-8"
    tabIndex="-1"
    aria-label="Page footer"
  >
    <footer class="pt-8 bg-primary-700 pb-12 px-3">
      <div class="container">
        <div class="grid grid-cols-12 gap-1">
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <section>
              <h3 class="font-display-regular text-primary-500 mb-2">
                Quick links
              </h3>
              <ul>
                <li v-for="link in quickLinks" :key="`quick-links-${link.title}`" class="mb-2"><a class="text-white" :href="link.link"><Icon path="ArrowRight" :height="16" :width="16" class="inline-block"/>{{ link.title }}</a></li>
              </ul>
            </section>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <section>
              <h3 class="font-display-regular text-primary-500 mb-2">
                Useful tools
              </h3>
              <ul>
                <li v-for="link in usefulTools" :key="`useful-tools-${link.title}`" class="mb-2"><a class="text-white" :href="link.link"><Icon path="ArrowRight" :height="16" :width="16" class="inline-block"/>{{ link.title }}</a></li>
              </ul>
              <Button class="mb-2 w-full sm:w-auto" variant="secondary" type="link" :external="true" link="https://www.studyaustralia.gov.au/en/plan-your-studies/find-a-course">Find a course <Icon class="inline-block" path="Search" :height="16" :width="16"/></Button>
            </section>
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-4">
            <section>
              <h3 class="font-display-regular text-primary-500 mb-2">
                Connect with us
              </h3>
              <ul class="flex gap-6">
                <li v-for="social in socials" :key="`social-${social.name}`">
                  <a
                    class="bg-primary-600 rounded-full p-3 flex justify-center text-white items-center hover:bg-white hover:text-primary"
                    :href="social.link"
                    :title="social.name"
                  >
                    <span class="sr-only">{{ social.name }}</span>
                   <Icon :path="social.name" :height="24" :width="24"/>
                  </a>
                </li>
              </ul>
            </section>
            <section class="mt-4">
              <h3 class="font-display-regular text-primary-500 mb-2">
                Subscribe to our newsletters
              </h3>
              <p class="mb-3 text-white">
                Updates on trade, investment, education and tourism delivered to your inbox.
              </p>
              <Button variant="secondary" class="w-full sm:w-auto" type="link" link="https://www.studyaustralia.gov.au/en/newsletter-sign-up" :external="true">Sign Up</Button>
            </section>
          </div>
        </div>
      </div>
    </footer>
    <footer class="bg-primary-700 px-3 pb-8">
      <div class="container">
        <hr class="w-full mb-6">
        <div class="md:flex flex-row align-items-center mb-6">
          <img
          class="w-24 h-24 me-6"
            src="/assets/images/kangaroo-logo.svg"
            alt="Australia's Nation Brand Kangaroo Logo"
          >
          <p class="text-left text-white m-0 font-au-sans-regular">
            In the spirit of reconciliation we acknowledge the Traditional Custodians of the country throughout Australia and their connections to land, sea and community. We pay our respect to their elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.
          </p>
        </div>
        <div class="md:flex flex-column text-white lg:flex-row lg:justify-between">
          <ul class="md:flex gap-6">
            <li><a class="text-white" href="https://www.studyaustralia.gov.au/en/about-us">About Us</a></li>
            <li><a class="text-white" href="https://www.austrade.gov.au/en/site-information/site-disclaimer.html">Site disclaimer</a></li>
            <li><a class="text-white" href="https://www.austrade.gov.au/en/site-information/privacy-policy.html">Privacy policy</a></li>
          </ul>
          <p> © 2024, Australian Trade and Investment Commission</p>
        </div>
      </div>
    </footer>
  </div>
</template>
